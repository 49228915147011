import React from 'react';
import { Box, Container, Grid, Typography, Link, useTheme, useMediaQuery } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import wisemediaLogo from '../assets/svg/wisemedia_square.svg';
import content from '../content.json';
import { ReactComponent as Logo } from '../assets/svg/logo.svg';
import { ReactComponent as ReprLogo } from '../assets/svg/repr.svg';

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: '#e6e6e6',
        py: 7,
        fontFamily: 'HelveticaNeue-Light, Arial, sans-serif',
        color: '#808080',
      }}
    >
      <Container 
        maxWidth={false}
        sx={{
          px: { xs: 3, sm: 5, md: 8 },
          margin: '0 auto',
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '150px' }}>
          <Grid container spacing={4} sx={{ flex: 1 }}>
            {isMobile ? (
              <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Logo style={{ width: '70px', height: '60px', fill: '#999999', marginBottom: '2rem', opacity: 0.75 }} />
                <Typography 
                  variant="body2"
                  color="#2f3330"
                  align="center" 
                  sx={{ 
                    // fontSize: '0.8rem', 
                    fontSize: '1.9vw', 
                    fontFamily: 'HelveticaNeue-Regular, Arial, sans-serif',
                    textTransform: 'uppercase',
                    mb: 2
                  }}
                >
                  <Link href={content.socialMedia.instagram} target="_blank" rel="noopener noreferrer" color="inherit" underline="none">
                    Instagram
                  </Link>
                  {' • '}
                  <Link href={content.socialMedia.tiktok} target="_blank" rel="noopener noreferrer" color="inherit" underline="none">
                    Tiktok
                  </Link>
                  {' • '}
                  <Link href={content.socialMedia.vimeo} target="_blank" rel="noopener noreferrer" color="inherit" underline="none">
                    Vimeo
                  </Link>
                  {' • '}
                  <Link href={content.socialMedia.youtube} target="_blank" rel="noopener noreferrer" color="inherit" underline="none">
                    Youtube
                  </Link>
                  {' • '}
                  <Link href={content.socialMedia.facebook} target="_blank" rel="noopener noreferrer" color="inherit" underline="none">
                    Facebook
                  </Link>
                  {' • '}
                  <Link href={content.socialMedia.linkedin} target="_blank" rel="noopener noreferrer" color="inherit" underline="none">
                    Linkedin
                  </Link>
                </Typography>
                <Box sx={{  height: '20px'}} />
                <Box sx={{ width: '5rem', height: '1px', backgroundColor: '#808080', mb: 2, opacity: 0.5}} />
                <Typography variant="body2" sx={{fontSize: { xs: '0.6rem', md: '0.8rem' }, fontFamily: 'inherit', textAlign: 'center' }}>
                  Copyright © {currentYear} all rights reserved.
                </Typography>
              </Grid>
            ) : (
              <>
                <Grid item xs={12} md={4}>
                  <Box
                    component="img"
                    src={wisemediaLogo}
                    alt="Wisemedia Logo"
                    sx={{ height: 35, mb: 2 }}
                    style={{ opacity: 0.5 }}
                  />
                  <Typography variant="body2" sx={{ fontSize: '0.8rem', fontFamily: 'inherit' }}>
                    {content.footer.address}
                  </Typography>
                  <Typography variant="body2" sx={{ fontSize: '0.8rem', fontFamily: 'inherit' }}>
                    {content.footer.email}
                  </Typography>
                  <Typography variant="body2" sx={{ fontSize: '0.8rem', fontFamily: 'inherit' }}>
                    {content.footer.phone}
                  </Typography>
                  <Typography variant="body2" sx={{ fontSize: '0.8rem', fontFamily: 'inherit', mt: 2, opacity: 0.6 }}>
                    {content.footer.cvr}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', pt: 0, mt: 0 }}>
                  <Logo style={{ width: '70px', height: '60px', fill: '#999999', marginBottom: '2.2rem', opacity: 0.75 }} />
                  <Typography 
                    variant="body2"
                    color="#2f3330"
                    align="center" 
                    sx={{ 
                      fontSize: '0.8rem', 
                      fontFamily: 'HelveticaNeue-Regular, Arial, sans-serif',
                      textTransform: 'uppercase',
                      mt: 0,
                    }}
                  >
                    <Link href={content.socialMedia.instagram} target="_blank" rel="noopener noreferrer" color="inherit" underline="none">
                      Instagram
                    </Link>
                    {' • '}
                    <Link href={content.socialMedia.tiktok} target="_blank" rel="noopener noreferrer" color="inherit" underline="none">
                      Tiktok
                    </Link>
                    {' • '}
                    <Link href={content.socialMedia.vimeo} target="_blank" rel="noopener noreferrer" color="inherit" underline="none">
                      Vimeo
                    </Link>
                    {' • '}
                    <Link href={content.socialMedia.youtube} target="_blank" rel="noopener noreferrer" color="inherit" underline="none">
                      Youtube
                    </Link>
                    {' • '}
                    <Link href={content.socialMedia.facebook} target="_blank" rel="noopener noreferrer" color="inherit" underline="none">
                      Facebook
                    </Link>
                    {' • '}
                    <Link href={content.socialMedia.linkedin} target="_blank" rel="noopener noreferrer" color="inherit" underline="none">
                      Linkedin
                    </Link>
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-end' }}>
                  <Box sx={{ pt: 1, mb: 0, opacity: 0.5 }}>
                    <ReprLogo style={{ width: '18vw', height: 'auto' }} />
                  </Box>
                  <Box sx={{ width: '5rem', height: '1px', backgroundColor: '#808080', mt: 5, mb: 0, opacity: 0.5}} />
                  <Typography variant="body2" sx={{ fontSize: '0.8rem', fontFamily: 'inherit', textAlign: 'right' }}>
                    Copyright © {currentYear} all rights reserved.
                  </Typography>
                  <Typography variant="body2" sx={{ fontSize: '0.7rem', fontFamily: 'inherit', textAlign: 'right', color: "#2f3330"}}>
                    <Link component={RouterLink} to="/tos" color="inherit" underline="always" >
                      terms and conditions
                    </Link>
                    {' / '}
                    <Link component={RouterLink} to="/privacy" color="inherit" underline="always">
                      privacy policy
                    </Link>
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
