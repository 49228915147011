import React from 'react';
import { ReactComponent as Logo } from '../assets/svg/logo.svg';
import wisemediaLogo from '../assets/svg/wisemedia_square.svg';

const WaitingPage = () => {
  return (
    <div 
      style={{ 
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        width: '100vw',
        height: '100vh',
        backgroundColor: 'white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 999,
        overflow: 'auto'
      }}
    >
      <div 
        style={{ 
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          maxWidth: '600px',
          width: '100%',
          padding: '20px',
          position: 'relative',
          top: '-2%'
        }}
      >
        {/* Alien Logo */}
        <div style={{ marginBottom: '2.5rem' }}> {/* Reduced space between logo and heading */}
          <Logo 
            style={{ 
              width: '70px', 
              height: '60px', 
              fill: '#808080',
              opacity: 0.6
            }} 
          />
        </div>

        {/* Text Content */}
        <div 
          style={{ 
            fontFamily: 'HelveticaNeue-Light, Arial, sans-serif',
            textAlign: 'center',
            width: '100%'
          }}
        >
          <h1 style={{ 
            fontSize: '1.5rem', 
            fontWeight: 300,
            color: '#666666', // Darker color for Hello world
            marginBottom: '1.5rem' // Wider space after heading
          }}>
            Hello, World.
          </h1>
          
          <div style={{ color: '#808080' }}>
            <p style={{ marginBottom: '0.5rem' }}>Something here is coming very soon.</p>
            <p style={{ marginBottom: '1.5rem' }}>Until then, please reach Søren at:</p> {/* Wider space after this section */}
          </div>

          {/* Contact Information */}
          <div style={{ 
            color: '#808080',
            opacity: 0.8 
          }}>
            <p style={{ marginBottom: '1.5rem' }}>@soerenmalik</p> {/* Wider space */}
            <p style={{ 
              marginBottom: '1.5rem', // Wider space
              color: '#a3a3a3'
            }}>
              soren@wise.media / soren@malikmedia.dk
            </p>
            <p>+45 25 60 95 42</p>
          </div>
        </div>

        {/* Wise Media Logo */}
        <div style={{ 
          marginTop: '8rem',
          display: 'flex',
          justifyContent: 'center'
        }}>
          <img 
            src={wisemediaLogo} 
            alt="Wise Media" 
            style={{ 
              height: '35px',
              opacity: 0.3
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default WaitingPage;