import React, { useState, useEffect, useCallback } from 'react';
import { Box, Typography, Grid, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import contentData from '../content.json';
import useNavbarHeight from '../hooks/useNavbarHeight';
import ReachOut from './ReachOut';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import mapStyles from './mapstyle.json';

const Contact = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const navbarHeight = useNavbarHeight();
  const [map, setMap] = useState(null);

  const center = {
    lat: 55.677777992869565,
    lng: 12.554765939712526
  };

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyAag7tEl3MN2bNOx-DAt6bxbd_lprmGqSo"
  });

  const onLoad = useCallback((map) => {
    setMap(map);
  }, []);

  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  useEffect(() => {
    if (map) {
      map.panTo(center);
    }
  }, [map, center]);

  return (
    <Box sx={{
      width: '100%',
      minHeight: `calc(100vh - ${navbarHeight}px)`,
      pt: `${navbarHeight}px`,
      display: 'flex',
      flexDirection: 'column',
    }}>
      <Grid container sx={{ width: '100%', flex: '1 0 auto' }}>
        <Grid item xs={12} md={6} sx={{
          backgroundColor: '#fdfcfb',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',

        }}>
          <Box sx={{ ml: '25.33%', mt: { xs: 8}, mb: { xs: 8}}}>
            <Typography variant="h5" component="h2" gutterBottom sx={{ fontFamily: 'HelveticaNeue-Bold, Arial, sans-serif' }}>
              {contentData.reachOut.title}
            </Typography>
            {contentData.reachOut.paragraphs.map((paragraph, index) => (
              <React.Fragment key={index}>
                {paragraph !== "" ? (
                  <Typography variant="body1" sx={{ mb: paragraph === "" ? 2 : 0, fontFamily: 'HelveticaNeue-Light, Arial, sans-serif' }}>
                    {paragraph}
                  </Typography>
                ) : (
                  <Box sx={{ height: '1em' }} />
                )}
              </React.Fragment>
            ))}
          </Box>
        </Grid>

        <Grid item md={6} sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          p: 0,
          height: isMobile ? '0px' : 'auto',
          overflow: 'hidden',
        }}>
          {isLoaded && (
            <GoogleMap
              mapContainerStyle={{
                width: '100%',
                height: '100%',
                display: isMobile ? 'none' : 'block',
              }}
              center={center}
              zoom={18}
              onLoad={onLoad}
              onUnmount={onUnmount}
              options={{
                styles: mapStyles
              }}
            >
              <Marker
                position={center}
              />
            </GoogleMap>
          )}
        </Grid>
      </Grid>

      <Box sx={{
        backgroundColor: '#f1f7f4',
        width: '100%',
        p: { xs: 3, md: 4 },
      }}>
        <ReachOut />
      </Box>
    </Box>
  );
};

export default Contact;