import React, { useState, useRef, useEffect, useMemo } from 'react';
import { Box, Button, Typography, useTheme, Radio, RadioGroup, FormControlLabel, TextField, useMediaQuery } from '@mui/material';
import formData from './form.json';

const cleanLabel = (label) => {
  return label.replace(/^[\d.]+\s*/, '').replace(/\d+\.\s*/g, '').trim();
};

const fixLabel = (label) => {
  label = label.replace(/^[\d.]+\s*/, '').replace(/\d+\.\s*/g, '').trim();
  return label.includes("Please submit") ? "Name" : label;

}



const splitLabel = (label) => {
  const parts = label.split(' - ');
  return {
    title: cleanLabel(parts[0]),
    subtitle: parts.length > 1 ? cleanLabel(parts[1]) : null
  };
};

const RadioInput = ({ question, value, onChange }) => {
  const otherInputRef = useRef(null);

  const handleChange = (e) => {
    const selectedValue = e.target.value;
    onChange(selectedValue);
    if (selectedValue === 'Other') {
      setTimeout(() => {
        otherInputRef.current?.focus();
      }, 0);
    }
  };

  const handleOtherChange = (e) => {
    const newValue = e.target.value;
    onChange(`Other: ${newValue}`);
  };

  return (
    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <RadioGroup value={value && value.startsWith('Other:') ? 'Other' : value || ''} onChange={handleChange}>
        {question.options.map((option) => (
          <FormControlLabel
            key={option.label}
            value={option.custom ? 'Other' : cleanLabel(option.label)}
            control={<Radio />}
            label={
              option.custom ? (
                <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                  <Typography 
                    sx={{ 
                      fontFamily: 'HelveticaNeue-Light, Arial, sans-serif',
                      cursor: 'pointer',
                      userSelect: 'none',
                    }}
                  >
                  </Typography>
                  <TextField
                    size="small"
                    placeholder="Other..."
                    value={value && value.startsWith('Other:') ? value.slice(7) : ''}
                    onChange={handleOtherChange}
                    onClick={() => handleChange({ target: { value: 'Other' } })}
                    InputProps={{
                      style: { fontFamily: 'HelveticaNeue-Light, Arial, sans-serif' }
                    }}
                    sx={{ flexGrow: 1 }}
                    inputRef={otherInputRef}
                  />
                </Box>
              ) : (
                <Typography 
                  sx={{ 
                    fontFamily: 'HelveticaNeue-Light, Arial, sans-serif',
                    cursor: 'pointer',
                    userSelect: 'none',
                    textAlign: 'center',
                  }}
                >
                  {cleanLabel(option.label)}
                </Typography>
              )
            }
          />
        ))}
      </RadioGroup>
    </Box>
  );
};

const TextInput = ({ question, value, onChange, multiline = false }) => {
  return (
    <Box sx={{ width: '100%', maxWidth: '800px', margin: '0 auto' }}>
      <TextField 
        size="small"
        placeholder={fixLabel(question.label).toLowerCase()}
        multiline={multiline}
        rows={multiline ? 4 : 1}
        fullWidth 
        value={value || ''}
        onChange={(e) => onChange(e.target.value)}
        InputProps={{
          style: { 
            fontFamily: 'HelveticaNeue-Light, Arial, sans-serif',
            width: '100%'
          }
        }}
        sx={{
          '& .MuiOutlinedInput-root': {
            width: '100%'
          },
          '& .MuiInputBase-input::placeholder': {
            fontStyle: 'italic'
          },
          width: '100%'
        }}
      />
    </Box>
  );
};

const ReachOut = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [currentStep, setCurrentStep] = useState(0);
  const [history, setHistory] = useState([]);
  const [currentQuestions, setCurrentQuestions] = useState([]);

  const questions = useMemo(() => [
    { id: '238664256', next: { 'Start a project with us': 1, 'Just chat': 6, 'Career with us': 3 }, renderNext: false },
    { id: '1481219121', next: 2, renderNext: false },
    { id: '991439493', next: 6, renderNext: false },
    { id: '1899561792', next: 4, renderNext: false },
    { id: '233931766', next: 5, renderNext: false },
    { id: '1120099714', next: 6, renderNext: false },
    { id: '264158744', next: 7, renderNext: false },
    { id: '1709516041', next: 8, renderNext: true },
    { id: '897027683', next: 9, renderNext: true },
    { id: '1183791716', next: 10, renderNext: true },
    { id: '1501189728', next: 11, renderNext: true },
    { id: '20459119', next: 12, renderNext: true },
    { id: '1688521463', next: 13, renderNext: true },
    { id: '110368661', next: 14, renderNext: true },
    { id: '1815614646', next: 'submit', renderNext: true },
  ], []);

  const [formValues, setFormValues] = useState(() =>
    questions.reduce((acc, question) => ({ ...acc, [question.id]: '' }), {})
  );

  useEffect(() => {
    if (questions[currentStep]) {
      let questionsToRender = [questions[currentStep]];
      let nextIndex = questions[currentStep].next;
      
      while (typeof nextIndex === 'number' && questions[nextIndex] && questions[nextIndex].renderNext) {
        questionsToRender.push(questions[nextIndex]);
        nextIndex = questions[nextIndex].next;
      }
      
      setCurrentQuestions(questionsToRender);
    } else {
      setCurrentQuestions([]);
    }
  }, [currentStep, questions]);

  const handleChange = (value, questionId) => {
    setFormValues(prev => ({ ...prev, [questionId]: value }));
  };

  const handleNext = () => {
    if (isLastStep) {
      handleSubmit();
    } else {
      let nextStep = questions[currentStep].next;
      setHistory(prev => [...prev, currentStep]);
      
      if (typeof nextStep === 'object') {
        const selectedOption = formValues[questions[currentStep].id];
        nextStep = nextStep[selectedOption] || 6;
      }

      if (nextStep === 'submit') {
        handleSubmit();
      } else {
        setCurrentStep(nextStep);
      }
    }
  };

  const handleBack = () => {
    if (history.length > 0) {
      const prevStep = history[history.length - 1];
      setCurrentStep(prevStep);
      setHistory(prev => prev.slice(0, -1));
      setCurrentQuestions([questions[prevStep]]);
    }
  };

  const renderQuestions = () => {
    if (isSubmitted) {
      return (
        <Box sx={{ width: '100%', textAlign: 'center' }}>
          <Typography variant="h5" component="h3" gutterBottom sx={{ 
            fontFamily: 'HelveticaNeue-Light, Arial, sans-serif',
            mb: 2,
          }}>
            Thank you!
          </Typography>
          <Typography variant="subtitle1" gutterBottom sx={{ 
            fontFamily: 'HelveticaNeue-Light, Arial, sans-serif',
            mb: 3,
            color: "#808080"
          }}>
            We'll get back to you ASAP
          </Typography>
        </Box>
      );
    }

    if (currentQuestions.length === 0) {
      return null;
    }

    return currentQuestions.map((question, index) => {
      const currentQuestion = formData.fields.find(field => field.id === question.id);
      const showTitleAndSubtitle = index === 0 && questions[currentStep] && !questions[currentStep].renderNext;
      return (
        <Box key={question.id} sx={{ width: '100%', mb: index < currentQuestions.length - 1 ? 1 : 0 }}>
          {showTitleAndSubtitle && (
            <>
              <Typography variant="h5" component="h3" gutterBottom sx={{ 
                fontFamily: 'HelveticaNeue-Light, Arial, sans-serif',
                mb: 0,
                textAlign: 'center',
              }}>
                {splitLabel(currentQuestion.label).title}
              </Typography>
              
              <Typography variant="subtitle1" gutterBottom sx={{ 
                fontFamily: 'HelveticaNeue-Light, Arial, sans-serif',
                mb: 3,
                textAlign: 'center',
                color: "#808080"
              }}>
                {splitLabel(currentQuestion.label).subtitle}
              </Typography>
            </>
          )}
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', mt: showTitleAndSubtitle ? 0 : 2 }}>
            {(() => {
              switch (currentQuestion.type) {
                case 'RADIO':
                  return <RadioInput question={currentQuestion} value={formValues[currentQuestion.id]} onChange={(value) => handleChange(value, currentQuestion.id)} />;
                case 'SHORT_ANSWER':
                  return <TextInput question={currentQuestion} value={formValues[currentQuestion.id]} onChange={(value) => handleChange(value, currentQuestion.id)} />;
                case 'LONG_ANSWER':
                  return <TextInput question={currentQuestion} value={formValues[currentQuestion.id]} onChange={(value) => handleChange(value, currentQuestion.id)} multiline />;
                default:
                  return null;
              }
            })()}
          </Box>
        </Box>
      );
    });
  };

  const isLastStep = currentQuestions[currentQuestions.length - 1]?.renderNext === true;
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = async () => {
    try {
      const baseUrl = `https://docs.google.com/forms/d/e/1FAIpQLSf5GdTJXevG-Yo7mx7rDBkCR069RP8-x2Yr_8tEe3IS0G303g/formResponse`;

      // Construct URL with parameters
      const url = new URL(baseUrl);
      Object.entries(formValues).forEach(([key, value]) => {
        if (value.startsWith('Other:')) {
          url.searchParams.append(`entry.${key}.other_option_response`, value.slice(7).trim());
          url.searchParams.append(`entry.${key}`, '__other_option__');
        } else {
          url.searchParams.append(`entry.${key}`, value);
        }
      });
      url.searchParams.append('submit', 'Submit');
    
      console.log(url.toString());

      fetch(url.toString(), {
        method: 'POST',
        mode: 'no-cors',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });
  
      console.log('Form submitted successfully');

      console.log('Form submitted', formValues);
      setIsSubmitted(true);
      // Reset the form after submission
      setFormValues(questions.reduce((acc, question) => ({ ...acc, [question.id]: '' }), {}));
      setCurrentStep(0);
      setHistory([]);
      setCurrentQuestions([]);
    } catch (error) {
      console.error('Error submitting form:', error);
      // Handle submission error (e.g., show an error message to the user)
    }
  };

  useEffect(() => {
    if (isSubmitted) {
      setCurrentQuestions([]);
    }
  }, [isSubmitted]);

  return (
      <Box sx={{ 
        backgroundColor: '#f1f7f4', 
        minHeight: '40vh', 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center',
        p: { xs: 2, sm: 3, md: 4 },
      }}>
        <Box
          component="form"
          onSubmit={(e) => {
            e.preventDefault();
            if (isLastStep) {
              handleSubmit()
            }
          }}
          sx={{
            width: '100%',
            maxWidth: '450px',
            margin: 'auto',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            p: { xs: 2, sm: 3, md: 4 },
          }}
        >
          {renderQuestions()}
          
          {!isSubmitted && (
            <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', mt: 3 }}>
              <Button
                onClick={handleBack}
                variant="contained"
                size={isMobile ? "medium" : "large"}
                disabled={history.length === 0}
                sx={{
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.primary.contrastText,
                  fontFamily: 'HelveticaNeue-Light, Arial, sans-serif',
                  borderRadius: '8px',
                  textTransform: 'lowercase',
                  boxShadow: 'none',
                  '&:hover': {
                    backgroundColor: `#404040 !important`,
                    color: `${theme.palette.primary.contrastText} !important`,
                  },
                  '&:active': {
                    backgroundColor: `${theme.palette.primary.main} !important`,
                    color: `${theme.palette.primary.contrastText} !important`,
                  },
                  '&:focus': {
                    backgroundColor: `#404040 !important`,
                    color: `${theme.palette.primary.contrastText} !important`,
                  },
                  '&:disabled': {
                    backgroundColor: theme.palette.action.disabledBackground,
                    color: theme.palette.primary.contrastText,
                    opacity: 0.6,
                  },
                }}
              >
                back
              </Button>
              <Button
                onClick={handleNext}
                variant="contained"
                size={isMobile ? "medium" : "large"}
                disabled={currentQuestions.length === 0 || currentQuestions.some(q => !formValues[q.id])}
                sx={{
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.primary.contrastText,
                  fontFamily: 'HelveticaNeue-Light, Arial, sans-serif',
                  borderRadius: '8px',
                  textTransform: 'lowercase',
                  boxShadow: 'none',
                  '&:hover': {
                    backgroundColor: `#404040 !important`,
                    color: `${theme.palette.primary.contrastText} !important`,
                  },
                  '&:active': {
                    backgroundColor: `${theme.palette.primary.main} !important`,
                    color: `${theme.palette.primary.contrastText} !important`,
                  },
                  '&:focus': {
                    backgroundColor: `#404040 !important`,
                    color: `${theme.palette.primary.contrastText} !important`,
                  },
                  '&:disabled': {
                    backgroundColor: theme.palette.action.disabledBackground,
                    color: theme.palette.primary.contrastText,
                    opacity: 0.6,
                  },
                }}
              >
                {isLastStep ? 'submit' : 'next'}
              </Button>
            </Box>
          )}
          {!isSubmitted && isLastStep && (
            <Box sx={{ mt: 2, textAlign: 'center' }}>
              <Typography variant="body2" sx={{ fontFamily: 'HelveticaNeue-Light, Arial, sans-serif', color: '#808080' }}>
                Also feel free to contact us directly at<br />info@wise.media or +45 26 60 95
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
  );
};

export default ReachOut;
