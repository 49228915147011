import React, { useState, useEffect, useRef, useMemo } from 'react';
import { AppBar, Box, Button, useMediaQuery, Slide, Grid } from '@mui/material';
import { useTheme, styled } from '@mui/material/styles';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import Hamburger from 'hamburger-react'
import WiseMediaLogo from '../assets/svg/wisemedia.svg';
import content from '../content.json';

const MenuList = styled('ul')(({ theme }) => ({
  position: 'relative',
  display: 'inline-flex',
  margin: 0,
  padding: 0,
  listStyle: 'none',
}));

const MenuLink = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'isActive'
})(({ theme, isActive }) => ({
  color: isActive ? '#bfbfbf' : '#595959',
  textDecoration: 'none',
  padding: '0.5rem 2.5rem',
  fontSize: '1.3rem',
  fontFamily: 'HelveticaNeue-Light, Arial, sans-serif',
  textTransform: 'lowercase',
  '&:active': {
    backgroundColor: 'transparent',
    color: '#2673ff',
  },
  '&:focus': {
    backgroundColor: 'transparent',
  },
  '&:hover': {
    backgroundColor: 'transparent',
    color: isActive ? '#bfbfbf' : '#2673ff',
  },
}));

const Navbar = ({ setIsNavbarLocked }) => {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [isOpen, setOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const menuRef = useRef(null);
  const [navbarHeight, setNavbarHeight] = useState(0);
  const navbarRef = useRef(null);
  const [lastScrollY, setLastScrollY] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      // Close drawer on window resize if not mobile anymore
      if (!isMobile && isOpen) {
        setOpen(false);
        setIsNavbarLocked(false);
        document.body.style.overflow = 'visible';
      }
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      // Ensure scrolling is re-enabled when component unmounts
      document.body.style.overflow = 'visible';
    };
  }, [isMobile, isOpen, setIsNavbarLocked]);

  useEffect(() => {
    const updateNavbarHeight = () => {
      if (navbarRef.current) {
        setNavbarHeight(navbarRef.current.offsetHeight);
      }
    };

    updateNavbarHeight();
    window.addEventListener('resize', updateNavbarHeight);

    return () => {
      window.removeEventListener('resize', updateNavbarHeight);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      setLastScrollY(currentScrollY);
    };

    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
  }, [lastScrollY, navbarHeight]);


  const handleNavItemClick = (path) => {
    navigate(path);
    if (isMobile) {
      setOpen(false);
      setIsNavbarLocked(false);
    }
  };

  const handleLogoClick = () => {
    if (isMobile && isOpen) {
      setOpen(false);
      setIsNavbarLocked(false);
    }
    navigate('/');
  };

  const handleDrawerToggle = () => {
    setOpen(prevState => !prevState);
    setIsNavbarLocked(prevState => !prevState);
  };

  useEffect(() => {
    if (isOpen) {
      // Disable scrolling when navbar is opened
      document.body.style.overflow = 'hidden';
      document.body.style.position = 'fixed';
      document.body.style.width = '100%';
    } else {
      // Re-enable scrolling when navbar is closed
      document.body.style.overflow = '';
      document.body.style.position = '';
      document.body.style.width = '';
    }

    return () => {
      // Cleanup: ensure scrolling is re-enabled when component unmounts
      document.body.style.overflow = '';
      document.body.style.position = '';
      document.body.style.width = '';
    };
  }, [isOpen]);


  const menuItems = useMemo(() => content.navigation.map(item => ({
    name: item.name,
    path: item.path.toLowerCase()
  })), []);


  return (
    <AppBar
      position="fixed"
      sx={{
        backgroundColor: '#faf9fa',
        zIndex: 1300,
        top: 0,
        width: '100vw',
        left: 0,
        right: 0
      }}
      elevation={0}
    >
      <Box
        sx={{
          width: '100%',
          margin: '0 auto',
          py: { xs: 2.25, sm: 1.5 },
          px: { xs: 2, sm: 4 }
        }}
      >
        {isMobile ? (
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', position: 'relative' }}>
            <Box sx={{
              zIndex: 10001,
              '& .burger-container': {
                WebkitTapHighlightColor: 'transparent',
                WebkitTouchCallout: 'none',
                WebkitUserSelect: 'none',
                MozUserSelect: 'none',
                MsUserSelect: 'none',
                userSelect: 'none'
              }
            }}
            >
              <div className="burger-container">
                <Hamburger
                  hideOutline={true}
                  size={24}
                  toggled={isOpen}
                  toggle={handleDrawerToggle}
                  color="#888"
                  distance="lg"
                  duration={0.3}
                />
              </div>
            </Box>

            <Button
              component={Link}
              to="/"
              onClick={handleLogoClick}
              sx={{
                display: 'flex',
                alignItems: 'center',
                p: 0,
                zIndex: 10001,
                '&:active': { backgroundColor: 'transparent' },
                '&:focus': { backgroundColor: 'transparent', outline: 'none' },
                '&:hover': { backgroundColor: 'transparent' }
              }}
            >
              <img
                src={WiseMediaLogo}
                alt="Wise Media Logo"
                style={{ height: '32px', width: 'auto', opacity: 0.65, transform: 'scale(0.5)' }}
              />
            </Button>

            <Box sx={{ width: 40 }} />

            <Box
              sx={{
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: '#faf9fa',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: 10000,
                opacity: isOpen ? 1 : 0,
                pointerEvents: isOpen ? 'auto' : 'none',
                // transition: 'opacity 300ms ease-in-out, transform 300ms ease-in-out',
                transform: isOpen ? 'translateX(0)' : 'translateX(-100%)',
                overflowY: 'auto',
                WebkitOverflowScrolling: 'touch',
              }}
            >
              {[{ name: 'Home', path: '/' }, ...menuItems].map((item) => (
                <Button
                  key={item.name}
                  component={Link}
                  to={item.path.toLowerCase()}
                  onClick={() => handleNavItemClick(item.path.toLowerCase())}
                  sx={{
                    fontFamily: 'HelveticaNeue-Light, Arial, sans-serif',
                    color: '#595959',
                    fontSize: '1.5rem',
                    textTransform: 'lowercase',
                    my: 2,
                    '&:hover': { color: '#2673ff' },
                  }}
                >
                  {item.name.toLowerCase()}
                </Button>
              ))}
            </Box>
          </Box>
        ) : (
          <Grid container sx={{ width: '100%' }}>
            <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Button
                component={Link}
                to="/"
                onClick={handleLogoClick}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  p: 0,
                  '&:hover': { backgroundColor: 'transparent' }
                }}
              >
                <img
                  src={WiseMediaLogo}
                  alt="Wise Media Logo"
                  style={{ height: '32px', width: 'auto', opacity: 0.65 }}
                />
              </Button>
            </Grid>
            <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <MenuList ref={menuRef} sx={{ display: 'flex', justifyContent: 'center' }}>
                {menuItems.map((item) => (
                  <li key={item.name}>
                    <MenuLink
                      component={Link}
                      to={item.path}
                      className="menu-link"
                      isActive={location.pathname === item.path}
                      onClick={() => handleNavItemClick(item.path)}
                    >
                      {item.name}
                    </MenuLink>
                  </li>
                ))}
              </MenuList>
            </Grid>
          </Grid>
        )}
      </Box>
    </AppBar>
  );
};

export default Navbar;
