import React, { useState, useEffect, useRef } from 'react';
import { Grid, Box, Typography, IconButton } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import contentData from '../content.json';
import { styled } from '@mui/material/styles';
import useNavbarHeight from '../hooks/useNavbarHeight';


const StyledTypography = styled(Typography)(({ theme }) => ({
  fontFamily: 'HelveticaNeue-Regular, Arial, sans-serif',
  lineHeight: 1.6,
  marginBottom: theme.spacing(2),
}));

const TitleTypography = styled(Typography)(({ theme }) => ({
  fontFamily: 'Microgramma-Bold, Arial, sans-serif',
  fontSize: {
    sm: '11.4rem',
  },
}));

const Cases = () => {
  const [isVimeoLoaded, setIsVimeoLoaded] = useState(false);
  const [playingVideo, setPlayingVideo] = useState(null);
  const playerRefs = useRef([]);
  const navbarHeight = useNavbarHeight();

  const sections = contentData.cases;

  useEffect(() => {
    // Load Vimeo Player API
    const script = document.createElement('script');
    script.src = 'https://player.vimeo.com/api/player.js';
    script.async = true;
    script.onload = () => setIsVimeoLoaded(true);
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    if (!isVimeoLoaded || typeof window.Vimeo === 'undefined') return;

    sections.forEach((section, index) => {
      const iframe = document.getElementById(`vimeo-player-${index}`);
      if (iframe) {
        playerRefs.current[index] = new window.Vimeo.Player(iframe);
        playerRefs.current[index].on('play', () => {
          sections.forEach((_, i) => {
            if (i !== index) {
              playerRefs.current[i]?.pause();
            }
          });
        });
      }
    });

    return () => {
      playerRefs.current.forEach(player => player?.off('play'));
    };
  }, [isVimeoLoaded, sections]);

  const handlePlayClick = (index) => {
    setPlayingVideo(index);
    playerRefs.current[index]?.play();
  };

  return (
    <Box sx={{ width: '100%', overflowX: 'hidden', pt: `${navbarHeight}px`, mt: 0 }}>
      {sections.map((section, index) => (
        <Grid container key={index} sx={{ mb: 0, width: '100%', backgroundColor: section.backgroundColor }} alignItems="center">
          <Grid item xs={12} md={6} order={{ xs: 1, md: section.videoOnLeft ? 1 : 2 }}>
            <Box sx={{ height: 0, paddingTop: '56.25%', position: 'relative' }}>
              <iframe
                id={`vimeo-player-${index}`}
                src={`https://player.vimeo.com/video/${section.videoId}?app_id=122963&api=1&controls=1`}
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  pointerEvents: 'auto',
                }}
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
                title={`Case Study ${index + 1} Video`}
              ></iframe>
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  backgroundImage: `url(${require(`../assets/images/${section.thumbnail}`)})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  opacity: playingVideo === index ? 0 : 1,
                  pointerEvents: playingVideo === index ? 'none' : 'auto',
                  transition: 'opacity 0.3s ease-in-out',
                }}
              >
                <IconButton
                  onClick={() => handlePlayClick(index)}
                  sx={{
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    '&:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.7)',
                    },
                  }}
                >
                  <PlayArrowIcon sx={{ fontSize: 60, color: 'white' }} />
                </IconButton>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} order={{ xs: 2, md: section.videoOnLeft ? 2 : 1 }}>
            <Box sx={{ 
              height: '100%', 
              display: 'flex', 
              flexDirection: 'column', 
              justifyContent: 'flex-start', 
              py: 2, 
              px: { xs: 4, md: 6 },
              textAlign: { xs: 'left', md: section.videoOnLeft ? 'left' : 'right' }
            }}>
              <TitleTypography  sx={{ 
                fontSize: { xs: '1.1rem', sm: '1.2rem', md: '1.3rem', lg: '1.4rem' }
              }}>
                {section.title}
              </TitleTypography>
              <StyledTypography variant="body1" color="text.secondary" sx={{ 
                fontSize: { xs: '0.9rem', sm: '1rem', md: '1.1rem', lg: '1.2rem' }
              }}>
                {section.content.description}
              </StyledTypography>
              <StyledTypography variant="body2" color="text.secondary" sx={{ 
                fontSize: { xs: '0.8rem', sm: '0.875rem', md: '0.95rem', lg: '1.075rem' }, 
                mt: 2 
              }}>
                {section.content.credits.map((credit, index) => (
                  <React.Fragment key={index}>
                    <strong>{credit.role}:</strong> {credit.name}<br />
                  </React.Fragment>
                ))}
              </StyledTypography>
            </Box>
          </Grid>
        </Grid>
      ))}
    </Box>
  );
};

export default Cases;
