import React from 'react';
import { Container, Typography, List, ListItem, ListItemText, Box, useTheme } from '@mui/material';
import contentData from '../content.json';

const Privacy = () => {
  const theme = useTheme();

  return (
    <Box sx={{
      backgroundColor: '#fdfcfb',
      minHeight: '100vh',
      pt: { xs: '80px', sm: '100px' }, // Adjust these values based on your navbar height
      pb: 6,
    }}> 
      <Box sx={{
        mx: '15%',
        [theme.breakpoints.up('md')]: {
          mx: '30%',
        }
      }}>
        <Container maxWidth={false} disableGutters sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
          <Typography
            variant="h4"
            component="h1"
            gutterBottom
            sx={{
              fontFamily: 'HelveticaNeue-Bold, Arial, sans-serif',
              mb: 3,
              color: theme.palette.text.primary
            }}
          >
            {contentData.privacy.title}
          </Typography>
          {contentData.privacy.introduction.map((paragraph, index) => (
            <Typography
              key={index}
              variant="body1"
              paragraph
              sx={{
                fontFamily: 'HelveticaNeue-Light, Arial, sans-serif',
                textAlign: 'justify',
                color: theme.palette.text.primary
              }}
            >
              {paragraph}
            </Typography>
          ))}
          <List disablePadding>
            {contentData.privacy.requirements.map((requirement, index) => (
              <ListItem key={index} alignItems="flex-start" sx={{ mb: 2, pl: 0 }}>
                <ListItemText
                  primary={
                    <Typography
                      variant="h6"
                      sx={{
                        fontFamily: 'HelveticaNeue-Bold, Arial, sans-serif',
                        mb: 1,
                        color: theme.palette.text.primary
                      }}
                    >
                      {`${index + 1}. ${requirement.title}`}
                    </Typography>
                  }
                  secondary={
                    <React.Fragment>
                      {requirement.description.map((line, lineIndex) => (
                        <Typography
                          key={lineIndex}
                          variant="body2"
                          component="div"
                          sx={{
                            fontFamily: 'HelveticaNeue-Light, Arial, sans-serif',
                            mb: 1,
                            textAlign: 'justify',
                            color: theme.palette.text.primary
                          }}
                        >
                          {line}
                        </Typography>
                      ))}
                    </React.Fragment>
                  }
                />
              </ListItem>
            ))}
          </List>
        </Container>
      </Box>
    </Box>
  );
};

export default Privacy;
