import React, { useState, useEffect } from 'react';
import { Typography, Box, Button, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ReachOut from './ReachOut';
import contentData from '../content.json';

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontFamily: 'HelveticaNeue-Regular, Arial, sans-serif',
  lineHeight: 1.6,
  color: '#808080',
}));

const Home = () => {
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const theme = useTheme();
  const [navbarHeight, setNavbarHeight] = useState(0);

  useEffect(() => {
    const navbar = document.querySelector('.MuiAppBar-root');
    if (navbar) {
      setNavbarHeight(navbar.offsetHeight);
    }
  }, []);

  const handleScroll = () => {
    const secondSection = document.getElementById('second-section');
    if (secondSection) {
      secondSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const renderTextContent = (content) => (
    <Box sx={{ maxWidth: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start'}}>
      <Typography variant="h2" component="h1" gutterBottom sx={{
        fontFamily: 'Microgramma-Bold, Arial, sans-serif',
        fontSize: { xs: '1rem', md: '1.2rem' },
        marginBottom: theme.spacing(3),
        color: '#808080',
        textAlign: 'justify',
      }}>
        {content.title}
      </Typography>
      {content.paragraphs && content.paragraphs.map((paragraph, index) => (
        <StyledTypography key={index} variant="body1" color="text.secondary" sx={{ 
          fontSize: { xs: '1rem', sm: '1.1rem', md: '1rem' },
          textAlign: 'justify',
          marginBottom: theme.spacing(2),
        }}>
          {paragraph}
        </StyledTypography>
      ))}
      <Button
        variant="text"
        sx={{
          alignSelf: 'flex-start',
          fontFamily: 'HelveticaNeue-Light, Arial, sans-serif',
          color: '#808080',
          padding: 0,
          minWidth: 0,
          '&:hover': {
            backgroundColor: 'transparent',
          },
        }}
        onClick={handleScroll}
        endIcon={<KeyboardArrowDownIcon sx={{ fontSize: '1.2rem', ml: -0.5 }} />}
      >
        SCROLL DOWN
      </Button>
    </Box>
  );

  const renderImageContent = (imageSrc) => (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        overflow: 'hidden',
      }}
    >
      <Box
        component="img"
        src={require(`../assets/images/${imageSrc}`)}
        alt="Content image"
        sx={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          objectPosition: '50% 20%',
          display: 'block',
        }}
      />
    </Box>
  );

  const renderVideoContent = (videoSrc, videoThumbnail) => (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        height: '100%',
        overflow: 'hidden',
      }}
    >
      {videoThumbnail && (
        <Box
          component="img"
          src={require(`../assets/images/${videoThumbnail}`)}
          alt="Video thumbnail"
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            opacity: isVideoLoaded ? 0 : 1,
            transition: 'opacity 0.5s ease-in-out',
          }}
        />
      )}
      <video
        autoPlay
        loop
        muted
        playsInline
        onLoadedData={() => {
          setIsVideoLoaded(true);
        }}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          opacity: isVideoLoaded ? 1 : 0,
          transition: 'opacity 0.5s ease-in-out',
        }}
      >
        <source src={require(`../assets/videos/${videoSrc}`)} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </Box>
  );
  return (
    <Box sx={{ width: '100%', overflowX: 'hidden', position: 'relative', backgroundColor: '#e6e6e6' }}>
      <Box sx={{ 
        display: 'flex', 
        flexDirection: { xs: 'column', md: 'row' }, 
        minHeight: { xs: 'auto', md: `calc(100vh - ${navbarHeight}px)` },
        marginTop: `${navbarHeight}px`,
        position: 'relative'
      }}>
        <Box sx={{ 
          width: { xs: '100%', md: '50%' }, 
          display: 'flex', 
          flexDirection: 'column', 
          // justifyContent: 'flex',
          textAlign: 'justify',
          alignItems: 'flex-start',
          px: { xs: 10,  md: 20 },
          py: { xs: 5,  md: 15 },
          position: 'relative',
          
        }}>
          {renderTextContent({
            title: contentData.home.title,
            paragraphs: contentData.home.paragraphs,
          })}
        </Box>
        <Box sx={{ width: { xs: '100%', md: '50%' }, height: { xs: '50vh', md: 'auto' }, position: 'relative' }}>
          {renderImageContent(contentData.home.imageSrc)}
        </Box>
      </Box>
      
      <Box id="second-section" sx={{ 
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative'
      }}>
        <Box sx={{ 
          width: '100%', 
          position: 'relative',
          overflow: 'hidden', // Keep this to prevent any overflow
          paddingTop: '56.25%', // 16:9 aspect ratio
        }}>
          <Box sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
          }}>
            {renderVideoContent(contentData.home.videoSrc, contentData.home.videoSrcThumbnail)}
          </Box>
        </Box>
        <Box sx={{ 
          width: '100%', 
          position: 'relative',
          marginTop: '-2px' // Keep this small negative margin to remove any potential gap
        }}>
          <ReachOut />
        </Box>
      </Box>
    </Box>
  );
};

export default Home;
