import React from 'react';
import { Box, Typography, Grid, useMediaQuery } from '@mui/material';
import useNavbarHeight from '../hooks/useNavbarHeight';
import { styled, useTheme } from '@mui/material/styles';
import contentData from '../content.json';


const TitleTypography = styled(Typography)({
  fontFamily: 'Microgramma-Bold, Arial, sans-serif',
  textAlign: 'center',
  marginBottom: 0,
});

const SubtitleTypography = styled(Typography)({
  fontFamily: 'HelveticaNeue-Light, Arial, sans-serif',
  textAlign: 'center',
  marginTop: 0,
  marginBottom: 0,
  opacity: 0.7,
});

const DescriptionTypography = styled(Typography)({
  fontFamily: 'HelveticaNeue-Light, Arial, sans-serif',
  textAlign: 'justify',
  marginTop: 0,
  marginBottom: 0,
  opacity: 0.7,
});

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontFamily: 'HelveticaNeue-Regular, Arial, sans-serif',
  lineHeight: 1.6,
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(2),
  padding: theme.spacing(0, 5),
  textAlign: 'justify',
}));

const MemberCard = styled(Box)(({ theme, backgroundColor }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  flexGrow: 1,
  backgroundColor: backgroundColor,
  padding: theme.spacing(0, 0, 4, 0), // Adds padding to the bottom
}));

const About = () => {
  const { teamMembers } = contentData.about;
  const navbarHeight = useNavbarHeight();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const aboutParagraphs = contentData?.about?.about_paragraphs || [];
  
  return (
    <Box sx={{ width: '100%', pt: `${navbarHeight}px`, mb: 0 }}>
      <Grid container spacing={0} sx={{ display: 'flex', flexGrow: 1, flexDirection: isMobile ? 'column' : 'row' }}>
        {teamMembers.map((member) => (
          <Grid item xs={12} md={4} key={member.name} sx={{ display: 'flex', minHeight: '500px' }}> {/* Set a minimum height */}
            <MemberCard backgroundColor={member.backgroundColor} sx={{ flexGrow: 1 }}>
              <Box
                component="img"
                src={require(`../assets/images/${member.image}`)}
                alt={member.name}
                sx={{
                  width: '100%',
                  height: 'auto',
                  objectFit: 'cover',
                }}
              />
              <Box
                sx={{
                  p: 2,
                  mt: 3,
                  mb: 3,
                  display: 'flex',
                  flexDirection: 'column',
                  flexGrow: 1,
                }}
              >
                <TitleTypography variant="h5" component="h2">
                  {member.name}
                </TitleTypography>
                <SubtitleTypography variant="subtitle1" component="h3" color="text.secondary">
                  {member.title}
                </SubtitleTypography>
                <StyledTypography variant="body2" color="text.secondary" align="center">
                  {member.bio}
                </StyledTypography>
              </Box>
            </MemberCard>
          </Grid>
        ))}
      </Grid>
      <Grid container sx={{ backgroundColor: '#fdfcfb', }} >
        <Grid item xs={12} sx={{ mx: isMobile ? '10%' : '20%', mt: 8, mb: 8, py: 4 }}>
          <Typography variant="h2" component="h1" gutterBottom sx={{
            fontFamily: 'Microgramma-Bold, Arial, sans-serif',
            fontSize: { xs: '1rem', md: '1.2rem' },
            marginBottom: theme.spacing(3),
            color: '#808080',
            textAlign: 'justify',
          }}>
            About
          </Typography>
          {aboutParagraphs.length > 0 ? (
        aboutParagraphs.map((paragraph, index) => (
          <React.Fragment key={index}>
            <DescriptionTypography textAlign="left">
              {paragraph}
            </DescriptionTypography>
            {index < aboutParagraphs.length - 1 && <Box sx={{ height: '20px' }} />}
          </React.Fragment>
        ))
      ) : (
        <DescriptionTypography>No about information available.</DescriptionTypography>
      )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default About;
