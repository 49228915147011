import { useEffect } from 'react';
import contentData from '../content.json';

const ImagePreloader = () => {
  useEffect(() => {
    const imagePaths = [
      contentData.home.imageSrc,
      contentData.home.videoSrcThumbnail,
      ...contentData.cases.map(caseItem => caseItem.thumbnail),
      ...contentData.about.teamMembers.map(member => member.image)
    ];

    const promises = imagePaths.map((path) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = require(`../assets/images/${path}`);
        img.onload = () => resolve(img); // Resolve the promise when image is loaded
        img.onerror = reject; // Reject the promise on error
      });
    });

    Promise.all(promises)
      .catch((error) => console.error("Error preloading images:", error));

  }, []);

  return null; 
};

export default ImagePreloader;
