import React, { useState, useEffect } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Box } from '@mui/material';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './components/Home';
import Cases from './components/Cases';
import About from './components/About';
import Contact from './components/Contact';
import TOS from './components/TOS';
import Privacy from './components/Privacy';
import WaitingPage from './components/WaitingPage';
import Footer from './components/Footer';
import ImagePreloader from './components/ImagePreloader';

const theme = createTheme({
  palette: {
    primary: {
      main: '#666666',
    },
    secondary: {
      main: '#e6e6e6',
    },
    background: {
      default: '#faf9fa', // Light grey background
    },
    text: {
      primary: '#666666', // Dark grey for primary text
      secondary: '#666666', // Medium grey for secondary text
    },
  },
  typography: {
    fontFamily: 'Microgramma-Regular, Arial, sans-serif',
    h1: {
      fontFamily: 'Microgramma-Bold, Arial, sans-serif',
    },
    h2: {
      fontFamily: 'Microgramma-Bold, Arial, sans-serif',
    },
    h3: {
      fontFamily: 'Microgramma-Bold, Arial, sans-serif',
    },
    h4: {
      fontFamily: 'Microgramma-Bold, Arial, sans-serif',
    },
    h5: {
      fontFamily: 'Microgramma-Bold, Arial, sans-serif',
    },
    h6: {
      fontFamily: 'Microgramma-Bold, Arial, sans-serif',
    },
    button: {
      fontFamily: 'HelveticaNeue-Bold, Arial, sans-serif',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          '&:hover, &:focus, &:active': {
            backgroundColor: 'transparent !important',
            boxShadow: 'none !important',
          },
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true, // Disable ripple globally
      },
      styleOverrides: {
        root: {
          '&:hover, &:focus, &:active': {
            backgroundColor: 'transparent !important',
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          '&:hover, &:focus, &:active': {
            backgroundColor: 'transparent !important',
          },
        },
      },
    },
    MuiTouchRipple: {
      styleOverrides: {
        root: {
          display: 'none',
        },
      },
    },
  },
});
const MainLayout = ({ children, isNavbarVisible, setIsNavbarLocked }) => {
  return (
    <Box display="flex" flexDirection="column" minHeight="100vh">
      <Navbar isVisible={isNavbarVisible} setIsNavbarLocked={setIsNavbarLocked} />
      <ScrollToTop />
      <Box component="main" flexGrow={1}>
        {children}
      </Box>
      <Footer />
    </Box>
  );
};

function App() {
  const [isNavbarVisible, setIsNavbarVisible] = useState(true);
  const [isNavbarLocked, setIsNavbarLocked] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (!isNavbarLocked) {
        const currentScrollY = window.scrollY;
        setIsNavbarVisible(currentScrollY === 0);
      }
    };

    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isNavbarLocked]);

  // Removed the useEffect hook for setting body padding
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ImagePreloader />
        <Routes>
          {/* Root path shows the WaitingPage */}
          <Route path="/" element={<WaitingPage />} />
          
          {/* All other routes use the main layout */}
          <Route path="/home" element={
            <MainLayout isNavbarVisible={isNavbarVisible} setIsNavbarLocked={setIsNavbarLocked}>
              <Home />
            </MainLayout>
          } />
          <Route path="/cases" element={
            <MainLayout isNavbarVisible={isNavbarVisible} setIsNavbarLocked={setIsNavbarLocked}>
              <Cases />
            </MainLayout>
          } />
          <Route path="/about" element={
            <MainLayout isNavbarVisible={isNavbarVisible} setIsNavbarLocked={setIsNavbarLocked}>
              <About />
            </MainLayout>
          } />
          <Route path="/contact" element={
            <MainLayout isNavbarVisible={isNavbarVisible} setIsNavbarLocked={setIsNavbarLocked}>
              <Contact />
            </MainLayout>
          } />
          <Route path="/tos" element={
            <MainLayout isNavbarVisible={isNavbarVisible} setIsNavbarLocked={setIsNavbarLocked}>
              <TOS />
            </MainLayout>
          } />
          <Route path="/privacy" element={
            <MainLayout isNavbarVisible={isNavbarVisible} setIsNavbarLocked={setIsNavbarLocked}>
              <Privacy />
            </MainLayout>
          } />
        </Routes>
      </ThemeProvider>
    </Router>
  );
}

// ScrollToTop component
function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export default App;
